.word-card {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border: 1px solid #ccc;
  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
}

/* Container for the card pool, allowing multiple rows */
.card-pool-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  border: 2px dashed #ccc;
}

/* Modal overlay covers the screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Modal content box */
.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 6px;
  min-width: 280px;
  max-width: 600px;
}

/* Delete button */
.delete-button {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  transition: 0.2s ease-in-out;
}

.delete-button:hover {
  background: darkred;
  transform: scale(1.1);
}

.card-pool-container {
  display: flex;
  flex-wrap: wrap;  /* allow multiple rows */
  gap: 1rem;
  padding: 1rem;
  border: 2px dashed #ccc; /* for visual reference */
}